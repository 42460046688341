<template lang="pug">
  Auth(@loggedIn="onLoggedIn"
    :redirectWithLoginFailed="true")
    NowLoading(:show="showNowLoading")
    div.wrap-create-meeting-get-token.f.fh
      //- ModuleTmp
      div.wrap-close
        v-icon(@click="$router.go(-1)" size="32px" color="#999") mdi-close
      div
        div.wrap-msg
          span.block.text-center.fz14.mb4 ミーティングの予定調整のために
          span.block.text-center.fz14 あなたのカレンダーの空き時間を参照します。
        SignIn(@signInSuccess="signInSuccess"
          :googleSignInLabel="'Googleカレンダーの空き時間を参照する'")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-create-meeting-get-token {
  width: 100%;
  min-height: 100vh;
  position: relative;
  .wrap-close {
    position: absolute;
    top: 8px;
    left: 8px;
  }
  .wrap-msg {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    min-width: 300px;
    margin: 0 auto;
  }
}
</style>

<script>
import { firebase } from '@/components/utils/firebase'
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapMutations: mapMutationsAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import SignIn from '@/components/sign-in/firebase-sign-in-ui'
import NowLoading from '@/components/shared/NowLoading'
// import Header from '@/components/shared/Header.vue'
// import ModuleTmp from '@/components/module/ModuleTmp'

export default {
  components: {
    Auth,
    SignIn,
    NowLoading
    // Header,
    // ModuleTmp
  },
  data() {
    return {
      showNowLoading: false
      // headerContent: {
      //   title: 'Tmp',
      //   left: {
      //     label: 'Home',
      //     icon: 'mdi-home',
      //     to: '/tmp'
      //   },
      //   right: {
      //     label: 'Acount',
      //     icon: 'mdi-account',
      //     to: '/tmp'
      //   }
      // }
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  methods: {
    ...mapMutationsAuth(['updateAccessToken']),
    onLoggedIn() {},
    async signInSuccess({ firebaseUser, token }) {
      this.showNowLoading = false

      this.updateAccessToken(token.accessToken)

      // await this.sleepByPromise(0.8)
      // let firebaseUser = await firebase.auth().currentUser
      // console.log('firebaseUser:', firebaseUser)

      if (firebaseUser.uid) {
        this.showNowLoading = true

        await firebase.auth().currentUser
        await database.userCollection().setGoogleToken(firebaseUser.uid, {
          idToken: token.idToken,
          accessToken: token.accessToken,
          createdBy: this.uid,
          createdAt: new Date()
        })

        this.showNowLoading = false

        // this.$router.push('/create-meeting')
        this.$router.push(
          `/create-meeting/send-attendee-events/${this.$route.params.meetingId}/google`
        )
      }
    },
    sleepByPromise(sec) {
      return new Promise((resolve) => setTimeout(resolve, sec * 1000))
    }
  }
}
</script>
